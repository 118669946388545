import QueryChart from "@/components/hCharts/queryChart";
import TopChart from "@/components/topChart";
export default {
  name: "qCharts",
  props: {
    param: {
      type: Object,
      required: false
    }
  },
  data: function data() {
    var _this = this;
    return {
      dateRange: [new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 1), new Date().getTime()],
      topDateRange: [new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 1), new Date().getTime()],
      allTimes: [],
      pickerOptions: {
        shortcuts: [{
          text: this.$t('dashborad.date-option.last-4h'),
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 4);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('dashborad.date-option.last-24h'),
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('dashborad.date-option.last-3d'),
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('dashborad.date-option.last-1w'),
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('dashborad.date-option.last-3w'),
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 21);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('dashborad.date-option.last-1m'),
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }],
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
            minDate = _ref.minDate;
          _this.choiceDate = minDate.getTime();
          if (maxDate) {
            _this.choiceDate = '';
          }
        },
        disabledDate: function disabledDate(time) {
          if (_this.choiceDate) {
            var one = 30 * 24 * 3600 * 1000;
            var minTime = _this.choiceDate - one;
            var maxTime = _this.choiceDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      }
    };
  },
  components: {
    TopChart: TopChart,
    QueryChart: QueryChart
  },
  methods: {
    changeRangeDate: function changeRangeDate(start, end) {
      this.topDateRange = [start, end];
    }
  },
  watch: {
    dateRange: {
      handler: function handler() {
        this.topDateRange = this.dateRange;
      }
    }
  }
};